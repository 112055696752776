<template>
  <div class="cardForm">
    <div class="cardFormInner" v-show="clientSecret">
      <div class="row">
        <div class="field">
          <label for="card-number-element">Credit Card Number</label>
          <div id="card-number-element" class="input empty"></div>
        </div>
      </div>
      <div class="row">
        <div class="field half-width">
          <label for="card-expiry-element">Expiry Month/Year</label>
          <div id="card-expiry-element" class="input empty"></div>
        </div>
        <div class="field half-width">
          <label for="card-cvc-element">CVC Code</label>
          <div id="card-cvc-element" class="input empty"></div>
        </div>
      </div>
    </div>
    <div class="text-center" style="padding: 20px 0" v-show="!clientSecret">
      <loading :active="!clientSecret" :can-cancel="false" :is-full-page="false">
      </loading>
    </div>

    <div class="error visible" role="alert" v-if="error">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
        <path class="base" fill="#000" d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"></path>
        <path class="glyph" fill="#FFF" d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"></path>
      </svg>
      <span class="message">{{ error }}</span>
    </div>

    <div v-if="showCardAlert" class="credit-card-only">
      <img src="@/assets/images/credit-only.webp" alt="Credit Card">
      <span class="d-block mt-3">We ask for a payment method so that your subscription and business can continue without interruption after your trial ends.</span>
    </div>

    <div v-if="plan || showCoupon" class="linebreak"></div>

    <div v-if="plan" class="order-summary">
      <div class="title">Order Summary</div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th style="min-width: 120px;">Total Due Today</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>Agency {{ plan.label }} Account:</strong> <span v-if="showTrial"><span class="trial-span">{{ plan.trial_period_days }} Days Free Trial</span> Then</span> <span class="price-span" :class="{'line-through': couponInfo}">${{ formatPrice(plan.amount_org) }}</span><span v-if="couponInfo" class="price-span">${{ formatPrice(this.planPrice) }}</span> Per <span class="text-capitalize">{{ plan.interval }}</span> - 
              <span class="promotion-count">{{ plan.features.Installs }}</span> Accounts, {{ plan.features.Users ? plan.features.Users : 'Unlimited' }} Users,<span v-if="plan.features.Zapier"><span class="promotion-count"> Zapier</span> Integration,</span><span v-if="plan.features.Department"><span class="promotion-count"> Department</span> Feature,</span> 100% White Label, Unlimited Text Messages, Unlimited Calls
            </td>
            <td style="vertical-align: top;text-align: right;padding-right: 10px; font-size: 16px; font-weight: bold">$<span v-if="showTrial">0.00</span><span v-else>{{ formatPrice(this.planPrice) }}</span></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="showCoupon" class="coupon-wrapper mt-4">
      <div class="row">
        <div class="col-md-8">
          <text-input :immediate="false" name="couponCode" v-model="couponCode" placeholder="Enter Discount Code"/>
        </div>
        <div class="col-md-4">
          <button @click="applyCoupon()" type="button" class="btn btn-default btn-success coupon-button" :disabled="couponButtonDisabled">
            <atom-spinner v-if="isLoadingCard || loading" slot="loading" :animation-duration="1500" :size="20" color="#FFF" />
            <span v-else>Apply</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

export default {
  props: {
    buttonDisabled: {},
    buttonText: {},
    addPaymentMethod: {
      default: true,
    },
    loading: {},
    plan: {},
    skipTrial: false,
    eventBus: null,
    showCoupon: {
      type: Boolean,
      default: true,
    },
    showCardAlert: {
      type: Boolean,
      default: true,
    },
    defaultCoupon: {
      type: String,
      default: '',
    },
    useMainStripe: {
      type: Boolean,
      default: true,
    }
  },

  components: {
    Loading,
  },

  data () {
    return {
      stripeAPIToken: '',
      clientSecret: null,
      error: null,
      cardElement: null,
      isLoadingCard: false,
      paymentMethodId: null,
      couponCode: undefined,
      couponInfo: undefined,
    }
  },

  mounted () {
    if (this.eventBus)
    {
      this.eventBus.$on('onValidateCard', row => {
        this.checkCard();
      })
    }
    if (this.useMainStripe)
      this.stripeAPIToken = process.env.VUE_APP_STRIPE_KEY
    else
      this.stripeAPIToken = this.$store.getters['auth/stripeConfig'];
    this.includeStripe()
    let pararms = {
      domain: window.location.host,
      use_main: this.useMainStripe,
    }
    this.$store.dispatch('subscription/getSecretLanding', pararms)
      .then(res => {
        this.clientSecret = res.data
      })
    
    if (this.defaultCoupon)
    {
      this.applyCoupon(this.defaultCoupon)
    }
  },

  computed: {
    couponButtonDisabled() {
      return this.loading || !this.couponCode || this.isLoadingCard
    },  
    businessUrl() {
      return this.callEnabled ? 'call' : 'message';
    },
    showTrial() {
      return !this.skipTrial && this.plan.trial_period_days
    },
    planPrice() {
      let res = this.plan.amount_org; 
      if (this.couponInfo) {
        if (this.couponInfo.amount_off)
          res = this.plan.amount_org - this.couponInfo.amount_off / 100
        else if (this.couponInfo.percent_off)
          res = this.plan.amount_org * (100 - this.couponInfo.percent_off) / 100
      }
      return res
    },
  },

  methods: {
    applyCoupon(code = null) {
      this.couponInfo = undefined
      this.isLoadingCard = true
      const param = {
        type: 'order',
        code: code || this.couponCode,
        is_default: !!code,
      }
      this.$store.dispatch('subscription/getCoupon', param)
        .then((res) => {
          if (res.meta && res.meta.Apply)
          {
            this.checkCouponApply(res)
          } else {
            this.couponInfo = res
          }
          this.isLoadingCard = false
        })
        .catch(errors => {
          this.isLoadingCard = false
        })
    },

    checkCouponApply(res)
    {
      let valid = false;
      let couponApply = res.meta ? res.meta.Apply : ''
      switch (couponApply) {
        case 'yearly':
          valid = this.plan && this.plan.interval == 'year' && this.plan.amount_org > res.amount_off / 100 * 2
          break;
        default:
          break;
      }
      if (valid)
        this.couponInfo = res
    },

    formatPrice(value) {
      let val = (value/1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    includeStripe () {
      // Stripe library already included
      if (document.getElementById('stripe-js')) {
        this.configureStripe()
        return
      }

      let documentTag = document
      let object = documentTag.createElement('script')
      let scriptTag = documentTag.getElementsByTagName('script')[0]

      object.id = 'stripe-js'
      object.src = '//js.stripe.com/v3/'
      object.addEventListener('load', (e) => { this.configureStripe() }, false)
      scriptTag.parentNode.insertBefore(object, scriptTag)
    },

    configureStripe () {
      let elementStyles = {
        base: {
          color: '#32325D',
          fontWeight: 500,
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          '::placeholder': {
            color: '#CFD7DF',
          },
          ':-webkit-autofill': {
            color: '#e39f48',
          },
        },
        invalid: {
          color: '#E25950',

          '::placeholder': {
            color: '#FFCCA5',
          },
        },
      }

      this.stripe = Stripe( this.stripeAPIToken )
      const elements = this.stripe.elements()

      if (document.getElementById('card-number-element')) {
        this.cardElement = elements.create('cardNumber', {
          style: elementStyles,
        })
        this.cardElement.mount('#card-number-element')
      }

      if (document.getElementById('card-expiry-element')) {
        let cardExpiryElement = elements.create('cardExpiry', {
          style: elementStyles,
        })
        cardExpiryElement.mount('#card-expiry-element')
      }

      if (document.getElementById('card-cvc-element')) {
        let cardCvcElement = elements.create('cardCvc', {
          style: elementStyles,
        })
        cardCvcElement.mount('#card-cvc-element')
      }
    },

    async checkCard () {
      // Card already confirmed
      if (this.paymentMethodId) {
        this.$emit('done', {
          id: this.paymentMethodId,
          coupon: this.couponInfo
        })
        return
      }

      this.error = null

      this.isLoadingCard = true
      const { setupIntent, error } = await this.stripe.confirmCardSetup(this.clientSecret, {
        payment_method: {
          card: this.cardElement,
        },
      })

      if (error) {
        this.error = error.message
        this.isLoadingCard = false
        this.$emit('failed')
      } else {
        this.paymentMethodId = setupIntent.payment_method

        if (this.addPaymentMethod) {
          this.$store.dispatch('subscription/addPaymentMethod', setupIntent.payment_method)
            .then((res) => {
              this.$emit('done', {
                id: setupIntent.payment_method,
                coupon: this.couponInfo
              })
              this.isLoadingCard = false
            })
            .catch(errors => {
              this.isLoadingCard = false
              this.$emit('failed')
            })
        } else {
          this.isLoadingCard = false
          this.$emit('done', {
            id: setupIntent.payment_method,
            coupon: this.couponInfo
          })
        }
      }
    },
  },
}
</script>

<style scoped>
.line-through {
  text-decoration: line-through;
}
.atom-spinner {
  margin: 0 auto;
}

.cardForm {
  background: transparent;
  padding: 0px;
  max-width: initial;
}

.cardForm label {
  color: #414141;
  margin-bottom: 5px;
}

.cardForm .row {
  margin-right: -15px;
  margin-left: -15px;
}

.cardForm #card-button {
  height: 60px;
}

.cardForm .credit-card-only {
  padding: 20px 0px;
  text-align: center;
}

.cardForm .linebreak {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
}

.cardForm .order-summary {
  margin-top: 15px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
  padding-bottom: 15px
}

.cardForm .order-summary .title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px
}

.cardForm .order-summary table {
  width: 100%;
}

.cardForm .order-summary table th {
  font-weight: bold;
  font-size: 14px;
  color: #414141;
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;
}

.cardForm .order-summary table td {
  padding: 10px 0px;
}

.cardForm .order-summary .trial-span {
  font-weight: bold;
  color: #13c70d;
  font-style: italic;
  font-size: 19px;
  padding: 0 4px;
  text-decoration: underline;
}

.cardForm .order-summary .price-span {
  font-weight: normal;
  font-size: 16px;
  display: inline-block;
  padding: 0 5px;
}

.cardForm #card-button span {
  font-size: 16px;
  font-weight: bold;
}

.cardForm button {
  margin: 30px 0px 10px 0px;
  width: 100%;
}

.cardForm .coupon-button {
  margin: 0;
}

span.original-count {
  text-decoration: line-through;
}

span.promotion-count {
  font-weight: bold;
  font-size: 17px;
  color: #1a76d2;
}

span.term-policy-span {
  display: block;
  padding-bottom: 20px;
  max-width: 550px;
  margin: auto;
  text-align: center;
}

</style>
